<template>
  <div class="param-table">
    <div class="top-param">
      <div class="left-tab">
        Basic parameters
      </div>
    </div>
    <div class="bot-table">
      <van-swipe :loop="false" :width="getWidthPx" @change="getIndex">
        <van-swipe-item v-for="(item, index) in tableData" :key="item[0]">
          <div class="bot-table-item">
            <div class="item-bg">
              <div class="item-top">
                <div class="left">Model</div>
                <div class="right">{{ item[0] }}</div>
              </div>
              <div class="item-body">
                <div
                  class="body-line"
                  v-for="(line, jndex) in columns"
                  :class="{ 'line-h': jndex % 2 != 0 }"
                >
                  <div class="body-left">
                    {{ line }}
                  </div>
                  <div class="body-right">{{ item[jndex + 1] }}</div>
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nowIndex: 0,
      columns: [
        "Battery Type",
        "Nominal Battery Voltage (V)",
        "Battery Voltage Range (V)",
        "Max. Continuous Charging/Discharging Current (A)",
        "Max. Charge/Discharge Power (W)",
        "Max. Input Power (W)",
        "Max. Apparent Power Output to Utility Grid (VA)",
        "Output Voltage Range (V)",
        "Back-up Nominal Apparent Power (VA)",
        "Max. Output Current (A)",
        "PV AFCI 3.0",
        "User Interface",
        "Communication with BMS",
      ],
    };
  },
  computed: {
    tableData() {
      let data = [
        [
          "GW6000-ET-20",
          "Li-Ion",
          "500",
          "150~720",
          "30/30",
          "9000/6600",
          "9,600",
          "6,000",
          "0~300",
          "6,000",
          "13.0 (17.4@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
        [
          "GW8000-ET-20",
          "Li-Ion",
          "500",
          "150~720",
          "30/30",
          "12000/8800",
          "12,800",
          "8,000",
          "0~300",
          "8,000",
          "17.4(23.3@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
        [
          "GW9900-ET-20(AUS)",
          "Li-Ion",
          "500",
          "150~720",
          "40/40",
          "15000/11000",
          "16,000",
          "9,900",
          "0~300",
          "10,000",
          "21.7(26.1@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
        [
          "GW10K-ET-20",
          "Li-Ion",
          "500",
          "150~720",
          "40/40",
          "15000/11000",
          "16,000",
          "10,000",
          "0~300",
          "10,000",
          "21.7(26.1@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
        [
          "GW12K-ET-20",
          "Li-Ion",
          "500",
          "150~720",
          "40/40",
          "18000/13200",
          "19,200",
          "12,000",
          "0~300",
          "12,000",
          "21.7(26.1@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
        [
          "GW15K-ET-20",
          "Li-Ion",
          "500",
          "150~720",
          "40/40",
          "24000/16500",
          "24,000",
          "15,000",
          "0~300",
          "15,000",
          "21.7 (26.1@60sec)",
          "Integrated",
          "LED, WLAN+APP",
          "RS485, CAN",
        ],
      ];
      return data;
    },
    getWidthPx() {
      return (100 / 375) * 355 * (document.body.clientWidth / 100);
    },
  },
  methods: {
    getIndex(index) {
      this.nowIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.param-table {
  width: 100%;
  height: 100%;
  .bot-table {
    .bot-table-item {
      width: 355px;
      height: 267px;
      padding-right: 12px;
      .item-bg {
        width: 100%;
        height: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        .item-top {
          width: 100%;
          height: 35px;
          background: linear-gradient(90deg, #ff7658 0%, #e70415 100%), #e70415;
          padding: 0 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left,
          .right {
            font-weight: 700;
            font-size: 13px;
            color: #ffffff;
            line-height: 19px;
            white-space: nowrap;
          }
          .left {
            width: 193px;
          }
          .right {
            width: 114px;
          }
        }
        .item-body {
          width: 100%;
          height: 232px;
          overflow-y: scroll;
          overflow-x: hidden;
          .body-line {
            padding: 0 12px;
            width: 100%;
            min-height: 35px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .body-left,
            .body-right {
              font-weight: 400;
              font-size: 13px;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.8);
              flex-shrink: 0;
            }
            .body-left {
              width: 193px;
            }
            .body-right {
              width: 114px;
            }
          }
          .line-h {
            background: #f0f0f0;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.top-param {
  width: 100%;
  padding: 0 16px;
  height: 43px;
  display: flex;
  align-items: center;
  .left-tab {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: rgba(0, 0, 0, 0.8);
  }
}
.van-swipe {
  .van-swipe__track {
    padding-left: 8px;
  }
  // .van-swipe-item {

  // }
  .van-swipe__indicators {
    position: relative;
    justify-content: center;
    bottom: 0;
    margin-top: 8px;
    .van-swipe__indicator--active {
      background-color: #e60012;
    }
  }
}
</style>
