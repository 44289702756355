<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  .top-img {
    padding: 18px;
    width: 280px;
    // height: 372px;
    border-radius: 9px;
    background: #ffffff;
  }
  .bot-img {
    width: 100%;
    .img-bot-title {
      padding: 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.8);
    }
    .close {
      pointer-events: all;
      position: absolute;
      right: 4px;
      top: 6px;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .bot-link-content {
      width: 100%;
      height: 74px;
      display: flex;
      align-items: center;
      justify-content: center;
      .left-btn {
        height: 74px;
        margin-right: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 32px;
          height: 32px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .icon:active {
          border-radius: 50%;
        }
        .text {
          margin-top: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          white-space: nowrap;
          color: rgba(0, 0, 0, 0.8);
        }
        // display: flex;
      }
      .right-btn {
        margin-right: 0px !important;
      }
    }
  }
  .p-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .popup-content {
    position: absolute;
    top: calc(50% - 76px);
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    z-index: 3000;
  }
  .popup-bot-content {
    border-radius: 12px 12px 0px 0px;
  }
  .content {
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .three-page {
      width: 100%;
      height: 100%;

      .top {
        z-index: 3;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        margin: 16px auto;
        padding: 4px;
        height: 32px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        pointer-events: all;
        .scene-btn {
          width: 88px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          .name {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
        .active-name {
          background: linear-gradient(90deg, #ff7658 0%, #e70415 100%);
          border-radius: 20px;
          .name {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
    }

    .info {
      position: absolute;
      bottom: 0px;
      z-index: 100;
      pointer-events: all;
      width: 100%;
      height: auto;
      border-radius: 12px 12px 0px 0px;
      background: #ffffff;
      .bot-str-icon {
        position: absolute;
        top: -48px;
        left: 50%;
        transform: translateX(-50%);
        height: 32px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: all;
        .left-item {
          border-radius: 20px;
          padding-left: 6px;
          padding-right: 16px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }
        .back-icon {
          background-image: url("../../assets/three/re-ac.svg") !important;
        }
        .left-icon {
          width: 32px;
          height: 32px;
          background-image: url("../../assets/three/fen.svg");
          background-size: 100%;
        }
        .right-text {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.6);
          white-space: nowrap;
        }
      }

      .bot-icon {
        position: absolute;
        top: -48px;
        right: 16px;
        width: 32px;
        height: 32px;
        // border-radius: 33.3333px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../assets/three/share.svg");
        background-size: 100%;
      }
      .bot-icon:active {
        background-image: url("../../assets/three/share-a.svg") !important;
      }
      .bot-table {
        .table-bg {
          width: 355px;
          height: 267px;
          padding-right: 12px;
          .item-bg {
            width: 100%;
            height: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow: hidden;
            .item-top {
              width: 100%;
              height: 35px;
              background: linear-gradient(90deg, #ff7658 0%, #e70415 100%),
                #e70415;
              padding: 0 12px;
              display: flex;
              align-items: center;
              color: #ffffff;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
            }
            .item-body {
              width: 100%;
              height: 210px;
              overflow-y: scroll;
              overflow-x: hidden;
              padding: 0 16px;
              margin-top: 12px;
              .item-item {
                display: flex;
                width: 100%;
                height: 24px;
                &:not(:first-child) {
                  margin-top: 12px;
                }
                .item-img {
                  width: 30px;
                  height: 24px;
                  margin-right: 12px;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .item-text {
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 24px;
                  color: rgba(0, 0, 0, 0.6);
                }
              }
            }
            .item-text-body {
              width: 100%;
              height: 210px;
              overflow: hidden;
              padding: 0 16px;
              margin-top: 12px;
              .sub-title {
                font-weight: 500;
                font-size: 13px;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.8);
              }
              .title-content {
                margin-top: 6px;
                width: 100%;
                height: 162px;
                overflow-y: scroll;
                font-weight: 400;
                font-size: 13px;
                line-height: 19px;
                color: rgba(0, 0, 0, 0.6);
              }
            }
          }
        }
      }
      .three-icon {
        position: absolute;
        top: -88px;
        right: 16px;
        width: 32px;
        height: 32px;
        border-radius: 33.3333px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .active-icon {
        background: #e8f3ff !important;
        color: #007aff;
      }
      .param {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  z-index: 213;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loading-img {
    width: 90px;
    height: 97px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .loading-text {
    font-style: normal;
    white-space: nowrap;
    font-weight: 400;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.6);
  }
  .loading-process {
    display: flex;
    margin-top: 8px;
    align-items: center;
    .jindu {
      width: 111px;
      height: 7px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 90px;
      position: relative;
      .jindu-tiao {
        height: 100%;
        background: #e60012;
        border-radius: 90px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .jindu-text {
      font-weight: 700;
      font-size: 13px;
      color: #000000;
      margin-left: 10px;
    }
  }
}
.zhanwei {
  width: 100%;
  height: 44px;
}
</style>
<template>
  <div class="index">
    <cus-header></cus-header>
    <div class="zhanwei"></div>
    <div class="p-container" v-if="isShare">
      <div class="popup-content">
        <div class="top-img" ref="shareImgRef">
          <img-three></img-three>
        </div>
      </div>
    </div>

    <van-popup
      v-model="isShare"
      class="popup-bot-content"
      position="bottom"
      style="background:#fff;height:0"
    >
      <!-- <div class="bot-img">
        <div class="img-bot-title">
          Share to
        </div>
        <div class="close" @click="close">
          <img src="@/assets/dialog/close.svg" alt="" />
        </div>
        <div class="bot-link-content">
          <div class="left-btn">
            <div class="icon" @click="copy">
              <img src="@/assets/dialog/link.svg" alt="" />
            </div>

            <div class="text">
              copy link
            </div>
          </div>
          <div class="left-btn right-btn">
            <div class="icon" @click="save">
              <img src="@/assets/dialog/save.svg" alt="" />
            </div>
            <div class="text">
              save the picture
            </div>
          </div>
        </div>
      </div> -->
    </van-popup>

    <div class="loading-icon" v-show="!isLoading">
      <!-- <van-loading type="spinner" size="20px" />
      <div class="loading-text">loading</div> -->
      <div class="loading-img">
        <img src="@/assets/detail/loading.svg" alt="" />
      </div>
      <div class="loading-text">Product model loading</div>
      <div class="loading-process">
        <div class="jindu">
          <div class="jindu-tiao" ref="processRef"></div>
        </div>
        <div class="jindu-text">{{ process }}%</div>
      </div>
    </div>
    <div class="content" ref="centerContentRef">
      <div class="three-page" ref="threePage">
        <div class="top" ref="threeTopRef" v-show="isLoading">
          <div
            class="scene-btn"
            v-for="(item, index) of sceneName"
            @click="changeRoute(item)"
            :class="item == itemTitle ? 'active-name' : ''"
          >
            <div class="name">{{ item }}</div>
          </div>
        </div>
        <!-- 场景 -->
        <Scene @loading="loading"></Scene>
      </div>
      <div class="info" v-show="isLoading">
        <div
          class="bot-str-icon"
          v-if="itemTitle == 'Structure'"
          @click="dynamicClick"
        >
          <div class="left-item" v-if="isPlay">
            <div class="left-icon back-icon"></div>
            <div class="right-text">Return</div>
          </div>
          <div class="left-item" v-if="isDisplay">
            <div class="left-icon"></div>
            <div class="right-text">Dynamic Display</div>
          </div>
        </div>
        <div
          class="three-icon"
          v-if="itemTitle == 'Parameter'"
          :class="isSce ? 'active-icon' : ''"
          @click="changeParam"
        >
          <img :src="isSce ? img1 : img2" alt="" />
        </div>
        <div class="bot-icon" @click="share"></div>
        <info-model
          v-if="itemTitle == 'Features'"
          key="Features"
          ref="feaModel"
        >
          <div class="top-param">
            <div class="left-tab">
              Features
            </div>
          </div>
          <div class="bot-table">
            <van-swipe :loop="false" :width="getWidthPx" ref="swipe">
              <van-swipe-item
                v-for="(item, index) in tableData"
                :key="'df' + index"
              >
                <div class="table-bg">
                  <div class="item-bg">
                    <div class="item-top">
                      ET G2 Series
                    </div>
                    <template v-if="index == 0">
                      <div class="item-body">
                        <div class="item-item" v-for="j in item">
                          <div class="item-img">
                            <img :src="j.img" alt="" />
                          </div>
                          <div class="item-text">
                            {{ j.datas }}
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="item-text-body">
                        <div class="sub-title">{{ item[0] }}</div>
                        <div class="title-content">{{ item[1] }}</div>
                      </div>
                    </template>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </info-model>
        <info-model v-if="itemTitle == 'Parameter'" key="Parameter">
          <div class="param">
            <param-table></param-table>
          </div>
        </info-model>
        <info-model
          v-if="itemTitle == 'Structure'"
          :style="`height:${23 / 3.75}vw`"
        >
        </info-model>
      </div>
    </div>
  </div>
</template>

<script>
import Scene from "./../../components/three-scene/index.vue";
import ImgThree from "./components/img-three.vue";
import infoModel from "./components/info-model.vue";
import ParamTable from "./components/param-table.vue";
import html2canvas from "html2canvas";
import bus from "./../../lib/bus";
import { copyText } from "@/utils/index.js";
export default {
  components: { infoModel, ParamTable, ImgThree, Scene },
  data() {
    return {
      img1: require("@/assets/three/size-a.svg"),
      img2: require("@/assets/three/size.svg"),
      sceneName: ["Features", "Parameter", "Structure"],
      itemTitle: "Features",
      isSce: false,
      isShare: false,
      isPlay: false, //开启3d演示
      isDisplay: true,
      innerMeshChild: false,
      timer: null,
      process: 0,
      tableData: [
        [
          {
            img: require("@/assets/features/1.svg"),
            datas: "Integrated dry contact for external loads",
          },
          {
            img: require("@/assets/features/2.svg"),
            datas: "Peak shaving ",
          },
          {
            img: require("@/assets/features/3.svg"),
            datas: "Integrated AFCI",
          },
          {
            img: require("@/assets/features/4.svg"),
            datas: "IP66 ingress protection",
          },
          {
            img: require("@/assets/features/5.svg"),
            datas: "Plug & Play",
          },
          {
            img: require("@/assets/features/6.svg"),
            datas: "Elegant and compact design",
          },
          {
            img: require("@/assets/features/7.svg"),
            datas: "Strong backup power supply",
          },
          {
            img: require("@/assets/features/8.svg"),
            datas: "Maximum 16 A DC input current per string",
          },
        ],
        [
          "6-15 KW   |   Three Phase   | Up to 3 MPPTS   |  Hybrid Inverter (HV)",
          "The ET G2 hybrid inverter is designed to maximise energy output, enhance self-consumption and facilitate extensive back-up power for homeowners. With power rating up to 15 kW, intelligent load controls and a wide battery voltage range, the inverter caters to individual needs. To secure a high level of energy autonomy, combine the hybrid inverter with GoodWe battery Lynx F G2, and connect the system to the GoodWe EV charger HCA and/or any smart-grid ready heat pump. By combining a variety of smart operation modes, the system can be optimized to further drive down energy cost.",
        ],
      ],
    };
  },
  mounted() {
    bus.$on("process", this.setProcess);
    bus.$on("three-icon-IsNormal", (isNormal) => {
      this.isSce = isNormal;
    });
    bus.$on("changeMeshBtn", () => {
      if (!this.isDisplay) {
        bus.$emit("resetAllNow");
      }
      this.structureEvent();
    });
    setTimeout(() => {
      this.$refs.swipe.resize();
    }, 2000);
  },
  watch: {
    isLoading() {
      if (this.$route.query && Object.keys(this.$route.query).length !== 0) {
        this.initShare(this.$route.query.mesh, this.$route.query.tab);
      }
    },
  },
  computed: {
    getWidthPx() {
      return (100 / 375) * 355 * (document.body.clientWidth / 100);
    },
    isLoading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(newV) {
        this.$store.state.isLoading = newV;
      },
    },
  },
  methods: {
    setProcess(s) {
      this.process = s;
      this.$refs.processRef.style.width =
        ((100 / 375) * 111 * (document.body.clientWidth / 100) * s) / 100 +
        "px";
    },
    loading() {
      this.isLoading = true;
    },
    initShare(mesh, tab) {
      this.changeRoute(tab);
      bus.$emit("changeMesh", mesh);
    },
    dynamicClick() {
      if (!this.isPlay) {
        bus.$emit("displayMesh", this.$store.state.selectMesh);
        this.isDisplay = false;
        this.timer = setTimeout(() => {
          this.isPlay = true;
          clearTimeout(this.timer);
          this.timer = null;
        }, 2000);
      } else if (!this.isDisplay) {
        bus.$emit("unDisplayMesh", this.$store.state.selectMesh);
        this.isPlay = false;
        this.timer = setTimeout(() => {
          this.isDisplay = true;
          clearTimeout(this.timer);
          this.timer = null;
        }, 2000);
      }
    },
    copy() {
      let nowHref = window.location.origin;
      let res1 = this.$store.state.selectName.replaceAll(" ", "");
      let res = res1.replace("·", "-");
      let urlShare = `${nowHref}/#/?mesh=${res}&&tab=${this.$store.state.nowTab.replaceAll(
        " ",
        ""
      )}`;
      const rst = copyText(urlShare);
      rst &&
        this.$toast.success({
          message: "Copy Successfully",
          className: "succ-width",
          duration: 2000,
        });
    },
    save() {
      html2canvas(this.$refs.shareImgRef, {
        backgroundColor: "#e8f4ff00",
        useCORS: true, // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
      }).then((canvas) => {
        this.downloadIamge(canvas, "pic");
      });
    },
    downloadIamge(canvas, name) {
      //下载图片地址和图片名
      canvas.toBlob((blob) => {
        let a = document.createElement("a"); // 生成一个a元素
        a.download = name || "photo"; // 设置图片名称
        a.href = URL.createObjectURL(blob); // 将生成的URL设置为a.href属性
        a.click();
        a.remove();
        this.$toast.success({
          message: "Successfully Saved",
          className: "succ-width",
          duration: 2000,
        });
      }, "image/svg"); //得到图片的base64编码数据
    },
    changeParam() {
      this.isSce = !this.isSce;
      if (this.isSce) {
        bus.$emit("showSize", this.$store.state.selectMesh);
        this.$toast({
          message: "Display Size",
          className: "hidden-size",
          duration: 2000,
        });
      } else {
        bus.$emit("hiddenSize", this.$store.state.selectMesh);
        this.$toast({
          message: "Hidden Size",
          className: "hidden-size",
          duration: 2000,
        });
      }
    },
    structureEvent() {
      if (!this.isDisplay) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.isPlay = false;
        this.isDisplay = true;
      }
    },
    leaveStructure() {
      if (!this.isDisplay) {
        bus.$emit("resetAllNow");
      }
      this.structureEvent();
    },
    leaveParamter() {
      if (this.isSce) {
        bus.$emit("hiddenSize", this.$store.state.selectMesh);
        this.isSce = false;
      }
    },
    setTable(meshName) {
      this.itemTitle = meshName;
      this.$store.state.nowTab = meshName;
    },
    changeRoute(meshName) {
      this.setTable(meshName);
      if (meshName == "Structure") {
        bus.$emit(
          "SwitchAccordingToStatus",
          this.$store.state.selectMesh,
          "low"
        );
        this.$store.state.isDown = true;
        this.$store.state.isStructure = true;
      } else {
        bus.$emit(
          "SwitchAccordingToStatus",
          this.$store.state.selectMesh,
          "height"
        );
        this.$store.state.isDown = false;
        this.$store.state.isStructure = false;
      }
      this.leaveStructure();
      this.leaveParamter();
    },
    share() {
      this.isShare = true;
    },
    close() {
      this.isShare = false;
    },
  },
};
</script>
