<template>
  <div class="img-content">
    <div class="top-con">
      <img :src="imgList[shareIndex]" alt="" />
    </div>
    <div class="center-link">
      <div class="left-link">
        <img src="@/assets/header/logo.svg" alt="" />
      </div>
      <div class="right-link" ref="qrCodeUrl"></div>
    </div>
    <div class="bot-text">
      <div class="text" v-for="item in featureData">
        <div class="text-icon">
          <img :src="item.img" alt="" />
        </div>
        <p v-for="content in item.datas">
          {{ content }}
        </p>
      </div>
    </div>
    <div class="line"></div>
    <div class="share-content">
      <div class="shart-item" @click="copy">
        <div class="item-img">
          <img src="@/assets/dialog/link.svg" alt="" />
        </div>
        <div class="item-text">copy link</div>
      </div>
      <div class="shart-item" @click="save">
        <div class="item-img">
          <img src="@/assets/dialog/save.svg" alt="" />
        </div>
        <div class="item-text">save the picture</div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      imgList: [
        require("@/assets/dialog/first.png"),
        require("@/assets/dialog/second.png"),
        require("@/assets/dialog/thrid.png"),
      ],
      meshMap: ["LFP 3000/HV", "LFP 5-10KWH/LV", "LFP 5000"],
      tabMap: ["Features", "Parameter", "Structure"],
    };
  },
  computed: {
    shareIndex() {
      return this.meshMap.findIndex(
        (item) => item === this.$store.state.meshType
      );
    },
    featureData() {
      let data = [
        {
          img: require("@/assets/features/1.svg"),
          datas: ["Integrated dry contact for external loads"],
        },
        {
          img: require("@/assets/features/2.svg"),
          datas: ["Peak shaving "],
        },
        {
          img: require("@/assets/features/3.svg"),
          datas: ["Integrated AFCI"],
        },
        {
          img: require("@/assets/features/4.svg"),
          datas: ["IP66 ingress protection"],
        },
        {
          img: require("@/assets/features/5.svg"),
          datas: ["Plug & Play"],
        },
        {
          img: require("@/assets/features/6.svg"),
          datas: ["Elegant and compact design"],
        },
        {
          img: require("@/assets/features/7.svg"),
          datas: ["Strong backup power supply"],
        },
        {
          img: require("@/assets/features/8.svg"),
          datas: ["Maximum 16 A DC input current per string"],
        },
        {
          img: require("@/assets/features/9.svg"),
          datas: ["100% unbalanced output"],
        },
      ];

      return data;
    },
  },
  mounted() {
    this.creatQrCode();
  },
  methods: {
    save() {
      this.$parent.save();
    },
    copy() {
      this.$parent.copy();
    },
    creatQrCode() {
      let width = this.$refs.qrCodeUrl.offsetWidth;
      let height = this.$refs.qrCodeUrl.offsetHeight;
      let nowHref = window.location.origin;
      let res1 = this.$store.state.selectName.replaceAll(" ", "");
      let res = res1.replace("·", "-");
      new QRCode(this.$refs.qrCodeUrl, {
        text: `${nowHref}/#/?mesh=${res}&&tab=${this.$store.state.nowTab.replaceAll(
          " ",
          ""
        )}`, // 二维码的内容
        width,
        height,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.L,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top-con {
    width: 100%;
    height: 142px;
    background: linear-gradient(
      180deg,
      #f0f0f0 0%,
      rgba(247, 248, 250, 0.7) 100%
    );
    // border-radius: 6px;
    img {
      width: 100%;
      height: 100%;
    }
    flex: 1;
  }
  .center-link {
    margin-top: 7px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .left-link {
      width: 129px;
      height: 28px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right-link {
      width: 64px;
      height: 64px;
    }
  }
  .bot-text {
    margin-top: 6px;
    width: 100%;
    height: 138px;
    flex: 1;

    .text {
      display: flex;
      margin-top: 10px;
      .text-icon {
        width: 15px;
        height: 15px;
        margin-right: 9px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 9.75px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.05);
    margin-bottom: 12px;
    margin-top: 14px;
  }
  .share-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .shart-item {
      &:not(:first-child) {
        margin-left: 24px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .item-img {
        width: 32px;
        height: 32px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>
